import React from "react";
import { Image } from "react-bootstrap";

function ArticleImage(props) {
  return (
    <div className="article-image">
      <Image src={props.image} alt={props.caption} fluid style={{ width: "100%" }} />
      <caption>{props.caption}</caption>
    </div>
  );
}

export default ArticleImage;