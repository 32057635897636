import React from "react";
import { AiFillHeart } from "react-icons/ai";

function Footer() {
  return (
    <div className="footer">
      <p>Built with <AiFillHeart className="footer-love" /> by HariboDev | All opinions are my own</p>
    </div>
  );
}

export default Footer;