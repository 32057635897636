import { Divider } from "@mui/material";
import React from "react";
import awsGamedayImage from "../../assets/images/aws-gameday.png";
import Article from "../../components/Article";

export const AwsGamedayDetails = {
  articleTitle: "AWS GameDay",
  articleDate: "2023-05-23",
  articleAuthor: "Harrison Cannon",
  articleImage: awsGamedayImage,
  articleBody: "AWS GameDay is a fun, interactive learning exercise designed to give you a chance to put your AWS skills to the test in a real-world, gamified, risk-free environment.",
  articleSlug: "aws-gameday"
};

function AwsGameday() {
  function ArticleContent() {
    return (
      <div>
        <h2>Background</h2>

        <p>
          AWS GameDay is a learning exercise designed to give you a chance to put your AWS skills to the test in a real-world, risk-free environment. You could be presented with a scenario such as a new product launch or an unexpected traffic spike, and your challenge is to work with a team to solve the problem and restore the service.
        </p>

        <h4>Benefits</h4>

        <ul>
          <li>
            AWS provide the scenario and the AWS environment free of charge allowing you to focus on the task at hand.
          </li>
          <li>
            Enhance your problem-solving skills and collaborate as a team with other AWS experts from across the industry.
          </li>
          <li>
            Chance to work closely with AWS employees and learn from their experience.
          </li>
        </ul>

        <Divider className="divider" />

        <h2>My Experience</h2>

        <p>
          I attended my first AWS GameDay in April 2023 along with some colleagues from work. At the start of the event, all teams attended an introduction call to explain the scenario and the rules of the game. We then split off into our teams and were given access to our AWS environment.
        </p>

        <p>
          Our scenario was to deploy multiple microservices to support a new product launch. In order to prepare for random chaos or failure, we had to ensure our services were highly available and resilient. Thankfully, we were&apos;t required to consider cost optimisation as this was a learning exercise.
        </p>

        <p>
          In the beginning, it was definitely a case of information overload. We had to quickly familiarise ourselves with the AWS environment and the scenario. We were also given a list of tasks to complete where the quicker we were, the more points we could earn. Within a matter of minutes it was clear to see which teams had experience as their microservices were already deployed and ready to receive traffic.
        </p>

        <p>
          Once we had deployed our microservices, it was just a case of making them more efficient and more resilient. However, throughout the event, we also had to deal with random chaos and failure. The longer we took to put out these fires, the more points we forfeited. This was a great way to test our skills and see how we would react in a real-world scenario. Thankfully, we were able to resolve all issues and restore the service.
        </p>

        <p>
          At the end of the event, all teams reconvened for a debriefing call to announce the winning and runner-up teams. We were also given the opportunity to ask questions and provide feedback on the event. It was also really useful to listen to other teams and learn how they approached the scenario to help us improve for next time.
        </p>

        <p>
          After the event, I caught up with the rest of my team to discuss our strategy and how we would adapt our approach for next time. This was a great opportunity to reflect on the event and learn from our experience both in the gamified environment and for real-world projects.
        </p>

        <Divider className="divider" />

        <h2>Closing Thoughts</h2>

        <p>
          I really enjoyed my first AWS GameDay and would highly recommend it to anyone who is interested in AWS. It was a great opportunity to work with other AWS experts from across the industry and learn from their experience.
        </p>

        <p>
          Before attending the AWS GameDay, I had a few years hands-on experience with AWS and it was definitely beneficial. However, I still learnt a lot from the event and it was great to see how other teams approached the scenario and I hope to attend another AWS GameDay in the future.
        </p>
      </div>
    );
  }

  return <Article
    article={AwsGamedayDetails}
    content={ArticleContent}
  />;
}

export default AwsGameday;