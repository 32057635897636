import React from "react";
import Logo from "../assets/images/Cloud3.svg";

function Home() {
  return (
    <div className="home">
      <img src={Logo} alt="HariboDev Logo" />
      <h1>HariboDev</h1>
    </div>
  );
}

export default Home;