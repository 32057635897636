import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

function ClientSideError(props) {
  return (
    <div>
      <div className="text-center">
        <h1 className="display-1 fw-bold">{props.statusCode || "404"}</h1>
        <p className="fs-3">
          <span className="text-danger">Oops!</span> {props.header || "Page not found."}
        </p>
        <p className="lead">
          {props.body || "The page you're looking for doesn't exist."}
        </p>
        <Button className="card-button" variant="primary" to="/" as={Link}>
          Go Home
        </Button>
      </div>
    </div>
  );
}

export default ClientSideError;