import React from "react";
import { Card } from "react-bootstrap";

function Note({ children }) {
  return (
    <Card className="article-note">
      <Card.Title>Note</Card.Title>
      <Card.Body>
        <Card.Text>
          {children}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}

export default Note;