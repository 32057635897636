import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Footer from "./components/Footer";
import ClientSideError from "./pages/errors/ClientError";
import StaticBlogOnAwsServerless from "./pages/articles/StaticBlogOnAwsServerless";
import AwsSubjectMatterExpert from "./pages/articles/AwsSubjectMatterExpert";
import AwsGameday from "./pages/articles/AwsGameday";
import AwsLondonSummit from "./pages/articles/AwsLondonSummit";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <div className="app">
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/blog/static-blog-on-aws-serverless" element={<StaticBlogOnAwsServerless />} />
          <Route exact path="/blog/aws-subject-matter-expert" element={<AwsSubjectMatterExpert />} />
          <Route exact path="/blog/aws-gameday" element={<AwsGameday />} />
          <Route exact path="/blog/aws-london-summit" element={<AwsLondonSummit />} />
          <Route path="*" element={<ClientSideError />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
