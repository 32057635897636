import { Divider } from "@mui/material";
import React from "react";
import ArticleImage from "../../components/ArticleImage";
import blogImage from "../../assets/images/blog.haribodev.uk.svg";
import TextLink from "../../components/TextLink";
import Note from "../../components/Note";
import Prerequisites from "../../components/Prerequisites";
import AcmCertificateType from "../../assets/images/static-blog-on-aws-serverless/AcmCertificateType.png";
import AcmRequest from "../../assets/images/static-blog-on-aws-serverless/AcmRequest.png";
import AcmIssued from "../../assets/images/static-blog-on-aws-serverless/AcmIssued.png";
import S3 from "../../assets/images/static-blog-on-aws-serverless/S3.png";
import CloudFrontOrigin from "../../assets/images/static-blog-on-aws-serverless/CloudFrontOrigin.png";
import WAF from "../../assets/images/static-blog-on-aws-serverless/WAF.png";
import Settings from "../../assets/images/static-blog-on-aws-serverless/Settings.png";
import ErrorResponses from "../../assets/images/static-blog-on-aws-serverless/ErrorResponses.png";
import Route53 from "../../assets/images/static-blog-on-aws-serverless/Route53.png";
import Https from "../../assets/images/static-blog-on-aws-serverless/Https.png";
import Article from "../../components/Article";

export const StaticBlogOnAwsServerlessDetails = {
  articleTitle: "Static Blog on AWS Serverless",
  articleDate: "2023-05-12",
  articleAuthor: "Harrison Cannon",
  articleImage: blogImage,
  articleBody: "Hosting websites can be expensive, especially if you are just starting out. This was an issue I encountered when starting out this blog. However, static website hosting can be achieved on Amazon Web Services (AWS) without costing you an arm and a leg.",
  articleSlug: "static-blog-on-aws-serverless"
};

function StaticBlogOnAwsServerless() {
  function ArticleContent() {
    return (
      <div>
        <h2>Background</h2>

        <p>
          Choosing AWS was an easy choice. If scalability, reliability and security are important to you, then AWS is the way to go. AWS is the most popular Cloud provider and has a wide range of services to choose from. AWS also has a free tier which is perfect for hosting a static website. But, feel free to use any Cloud provider you like.
        </p>

        <p>
          Below is a high-level overview of the architecture we will be using. If you&apos;re new to AWS and it looks a bit daunting, don&apos;t worry, we&apos;ll break it down into smaller chunks as we go along.
        </p>

        <ArticleImage
          image={blogImage}
          caption="Static Blog on AWS Serverless"
        />

        <Divider className="divider" />

        <Prerequisites>
          <li>AWS Account</li>
          <li>Static build of your blog</li>
        </Prerequisites>

        <Divider className="divider" />

        <h2>Steps</h2>

        <h3>Route53</h3>

        <p>
          The first step is to handle user requests. When a user types <code>https://yourwebsite.com</code> into a web browser, you need to direct them to your website using a Domain Name System (DNS) service. Whilst common providers include <TextLink url="https://domains.google.com/registrar" text="Google Domains" /> and <TextLink url="https://www.godaddy.com/en-uk/domains" text="Go Daddy" />, AWS are also in the mix with their <TextLink url="https://aws.amazon.com/route53/" text="Route53 service" />.
        </p>

        <Note>
          Route53 is Amazon&apos;s DNS service providing functionality for domain registration and DNS record management.
        </Note>

        <p>
          Once you&apos;ve registered your domain name, keep Route53 handy as we&apos;ll be using it later on.
        </p>

        <h3>Certificate Manager (ACM)</h3>

        <p>
          Next, we need to create the SSL certificates so that users can access our website using <code>HTTPS</code> rather than <code>HTTP</code>. To do this, we&apos;ll be using <TextLink url="https://aws.amazon.com/certificate-manager/" text="AWS Certificate Manager (ACM)" />.
        </p>

        <Note>
          ACM is a service that lets you provision, manage and deploy SSL/TLS certificates for use with AWS services.
        </Note>

        <p>
          In the <code>N. Virginia</code> region n the ACM console, click on the <code>Request certificate</code> link in the left-hand menu. You will be presented with a screen asking you to choose the type of certificate you want to request. Select <code>Request a public certificate</code> and click <code>Request a certificate</code>.
        </p>

        <ArticleImage
          image={AcmCertificateType}
          caption="ACM Certificate Type"
        />

        <p>
          On the next screen, enter your domain name such as <code>yourwebsite.com</code>. The domain name you enter must match the domain you own on Route53 or another DNS provider. You could also choose to host the website on a subdomain such as <code>blog.yourwebsite.com</code>.
        </p>

        <p>
          Next, you&apos;ll be asked to choose a validation method. Select the <code>DNS validation - recommended</code> option. This means we will be required to enter some DNS records onto our DNS service to prove to ACM that we own that domain. With the rest of the options left as their defaults, click <code>Request</code>.
        </p>

        <ArticleImage
          image={AcmRequest}
          caption="ACM Request"
        />

        <p>
          Once you&apos;ve requested the certificate, you&apos;ll be presented with a screen showing the status of the certificate. It will initially be <code>Pending validation</code> and will change to <code>Issued</code> once the DNS records have been entered. If you have registered your domain with Route53, you can click the <code>Create record in Route53</code> button to automatically create the DNS records for you. If you&apos;re using another DNS provider, you&apos;ll need to manually create the DNS records, copying across the <code>CNAME</code> record name and value from the ACM console. It could take up to several hours for the certificate to be issued, however, in my experience, it&apos;s usually done within a couple of minutes.
        </p>

        <ArticleImage
          image={AcmIssued}
          caption="ACM Issued"
        />

        <h3>Simple Storage Service (S3)</h3>

        <p>Now that we&apos;ve got the domain and the accompanying SSL certificate, we can now create host the website. To do this, we&apos;ll need an origin where our website will reside. For this, we&apos;ll use Amazon Simple Storage Service (S3).</p>

        <Note>
          S3 is an object storage service that offers industry-leading scalability, data availability, security and performance.
        </Note>

        <p>
          Head over to the S3 console and click <code>Create bucket</code>. Enter a name for your bucket such as <code>yourwebsite.com</code> and select the <code>N. Virginia</code> region. With the rest of the options left as their defaults, click <code>Create bucket</code>.
        </p>

        <p>
          Once the bucket has been created, click on the bucket name and then click the <code>Upload</code> button. Next, drag and drop your website&apos;s static build files onto the screen and click <code>Upload</code>.
        </p>

        <ArticleImage
          image={S3}
          caption="Upload to S3"
        />

        <h3>CloudFront</h3>

        <p>
          Now that we&apos;ve got the website files in the bucket, we need to serve them on our domain. For this, we&apos;ll use <TextLink url="https://aws.amazon.com/cloudfront/" text="Amazon CloudFront" />.
        </p>

        <Note>
          CloudFront is a content delivery network (CDN) service that securely delivers data, videos, applications and APIs to customers globally with low latency, high transfer speeds and no commitments.
        </Note>

        <p>
          On the CloudFront console, click <code>Create Distribution</code>. On the next screen, choose the S3 Bucket you have just created, in this case, <code>yourwebsite.com</code>.
        </p>

        <p>
          For the <code>Origin access</code> setting, select <code>Origin access control settings (recommended)</code>. This will block direct access to our S3 bucket uncless it is CloudFront. CloudFront will only go to S3 if it&apos;s has expired, or the object you&apos;re trying to access doesn&apos;t exist in the cache. To configure this, click the <code>create control setting</code> button, leave all the options as their defaults, and then click <code>Create</code>.
        </p>

        <ArticleImage
          image={CloudFrontOrigin}
          caption="CloudFront Origin"
        />

        <p>
          Under the <code>Default cache behavior</code> heading, find the <code>Viewer protocol policy</code> setting and select <code>Redirect HTTP to HTTPS</code>. As it says on the tin, this will redirect all HTTP requests to HTTPS.
        </p>

        <p>
          To help save costs, we won&apos;t use the <TextLink url="https://aws.amazon.com/waf/" text="Web Application Firewall (WAF)" /> so under the <code>Web Application Firewall (WAF)</code> heading, select <code>Do not enable security protections</code>.
        </p>

        <ArticleImage
          image={WAF}
          caption="Web Application Firewall (WAF)"
        />

        <p>
          Under the <code>Settings</code> heading, locate the <code>Price class</code> setting. The option you choose here will depend on your budget and the location of your users. For this example, I&apos;ve chosen <code>Use only U.S., Canada and Europe</code> as this is where the majority of my users are located.
        </p>

        <p>
          For the <code>Alternate domain name (CNAME) - optional</code> setting, click the <code>Add item</code> button and enter your domain name, in this case, <code>yourwebsite.com</code>. This will associate your domain name with the CloudFront distribution.
        </p>

        <p>
          For the <code>Custom SSL certificate - optional</code> setting, select the certificate you previously created in the ACM console.
        </p>

        <Note>
          If your certificate is not showing up, make sure you created the certificate in the <code>N. Virginia</code> region in the ACM console.
        </Note>

        <ArticleImage
          image={Settings}
          caption="Settings"
        />

        <p>
          For the <code>Default root object - optional</code>, enter <code>index.html</code>. This will tell CloudFront to serve the <code>index.html</code> file when a user visits your domain.
        </p>

        <p>
          With the rest of the options left as their defaults, click <code>Create distribution</code>. Once it has been created, you will be presented with a screen showing the status of the distribution. Initally, the distribution will have an <code>In Progress</code> status and may take a few minutes to change to <code>Deployed</code>.
        </p>

        <p>
          Once the distribution has been deployed, you will be presented with a screen showing the distribution&apos;s details. Copy the <code>ARN</code> of the distribution and head back over to the S3 bucket.
        </p>

        <p>
          To allow CloudFront access to the S3 bucket, you need to change it&apos;s permissions. Click on the <code>Permissions</code> tab and click <code>Edit</code> on the Bucket Policy. Paste in the below JSON policy and change the <code>BUCKET_NAME</code> to the name of your S3 Bucket, in this case <code>yourwebsite.com</code>. You will also need to change <code>DISTRIBUTION_ARN</code> to the ARN of your CloudFront distribution. Once you&apos;ve done this, click <code>Save changes</code>.
        </p>

        <pre>
          {JSON.stringify({
            "Version": "2008-10-17",
            "Id": "PolicyForCloudFrontPrivateContent",
            "Statement": [
              {
                "Sid": "AllowCloudFrontServicePrincipal",
                "Effect": "Allow",
                "Principal": {
                  "Service": "cloudfront.amazonaws.com"
                },
                "Action": "s3:GetObject",
                "Resource": "arn:aws:s3:::BUCKET_NAME/*",
                "Condition": {
                  "StringEquals": {
                    "AWS:SourceArn": "DISTRIBUTION_ARN"
                  }
                }
              }
            ]
          }, null, 4)}
        </pre>

        <h4>Custom Error Responses</h4>

        <p>
          If you&apos;re like me and have used ReactJS for the development of your website, you may find that you&apos;re unable to visit any page other than the homepage. This is because ReactJS uses client-side routing and CloudFront is expecting a file to be served from the S3 bucket. To fix this, we need to create a <code>Custom Error Response</code> in the CloudFront console.
        </p>

        <p>
          From the CloudFront console, select the CloudFront distribution you have just deployed. Under the <code>Error pages</code> tab, click the <code>Create custom error response</code> button. In the <code>HTTP error code</code> field, enter <code>404: Not Found</code>. In the <code>Customize error response</code> section, select <code>Yes</code>. In the <code>Response page path</code> field, enter <code>/index.html</code>. In the <code>HTTP Response code</code> field, enter <code>200: OK</code>. With the rest of the options left as their defaults, click <code>Create</code>.
        </p>

        <p>
          You might want to repeat the above step for the <code>403: Forbidden</code> HTTP error code but this is optional and depends on your use case.
        </p>

        <ArticleImage
          image={ErrorResponses}
          caption="Custom Error Responses"
        />

        <p>
          Again, this will take a few minutes to deploy.
        </p>

        <h3>Route53 (Again)</h3>

        <p>
          The final stage is to point our domain to our CloudFront distribution. On the Route53 console, select the hosted zone for your domain, in this case <code>yourwebsite.com</code>. Click the <code>Create Record</code> button and select the <code>A - Routes traffic to an IPv4 address and some AWS Services</code> for the <code>Record Type</code>. Enable the <code>Alias</code> toggle and select <code>Alias to CloudFront distribution</code> for the <code>Route traffic to</code> dropdown. In the next field, select the CloudFront distribution you created earlier. Click <code>Create Records</code> to finish.
        </p>

        <ArticleImage
          image={Route53}
          caption="Route53"
        />

        <h3>Test</h3>

        <p>
          To test that everything is working as expected, visit your domain in a browser, in this case, <code>https://yourwebsite.com</code>. You should see your website load with the padlock icon in the address bar indicating that the website is secure.
        </p>

        <ArticleImage
          image={Https}
          caption="HTTPS"
        />

        <h2>Next Steps</h2>

        <p>
          There&apos;s a few things you can do to improve your website&apos;s performance and security. I&apos;ve listed a few below:
        </p>

        <ul>
          <li>
            <strong>Enable logging</strong> - This will allow you to see who is accessing your website and how often.
          </li>
          <li>
            <strong>Enable WAF</strong> - This will allow you to block malicious traffic from accessing your website.
          </li>
        </ul>

        <p>
          If you&apos;re versioning your code in a Git repository, you can automate the deployment of your website using <a href="https://aws.amazon.com/codepipeline/">AWS CodePipeline</a>. This will allow you to push your code to your Git repository and have it automatically deployed to your S3 bucket.
        </p>

        <p>
          In my case, I&apos;m using GitHub so I developed a pipeline using GitHub Actions to deploy my website to my S3 bucket and invalidate the CloudFront cache to ensure my users are seeing the latest version of my website.
        </p>
      </div>
    );
  }

  return <Article
    article={StaticBlogOnAwsServerlessDetails}
    content={ArticleContent}
  />;
}

export default StaticBlogOnAwsServerless;