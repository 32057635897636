import React from "react";

function Prerequisites({ children }) {
  return (
    <div className="article-prerequisites">
      <h2>Prerequisites</h2>
      <p>Before following along, make sure you have the following prerequisites in place:</p>
      <ul>
        {children}
      </ul>
    </div>
  );
}

export default Prerequisites;