import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";

function ArticleCard(props) {
  return (
    <Card className="article-card article-card-hover">
      <Card.Title>
        <h2>{props.articleTitle}</h2>
      </Card.Title>
      <Card.Body>
        <Card.Img src={props.articleImage} />
        <Card.Text>
          {props.articleBody}
        </Card.Text>
      </Card.Body>
      <Card.Footer className="article-card-footer">
        <caption>{props.articleDate}</caption>
        {props.articleSlug ?
          <Link to={`/blog/${props.articleSlug}`}>Read More <BsArrowRight /></Link>
          :
          <caption>Coming soon...</caption>
        }
      </Card.Footer>
    </Card>
  );
}

export default ArticleCard;