import React from "react";
import { Navbar, Container, Nav, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Cloud3 from "../assets/images/Cloud3.svg";
import { AiOutlineMenu } from "react-icons/ai";

function Header() {
  return (
    <Navbar className="nav-bar" expand="lg" sticky="top">
      <Container fluid>
        <Navbar.Brand to="/" as={Link}>
          <Image src={Cloud3} width={75} height={50} />
          <span className="nav-bar-logo">HariboDev</span>
        </Navbar.Brand>
        <Navbar.Toggle className="nav-bar-toggle">
          <AiOutlineMenu />
        </Navbar.Toggle>

        <Navbar.Collapse className="nav-bar-links">
          <Nav>
            <Nav.Link to="/" as={Link}>Home</Nav.Link>
            <Nav.Link to="/blog" as={Link}>Blog</Nav.Link>
            <Nav.Link to="https://github.com/HariboDev" as={Link} target="_blank" rel="noreferrer">GitHub</Nav.Link>
            <Nav.Link to="https://www.npmjs.com/~haribodev" as={Link} target="_blank" rel="noreferrer">NPM</Nav.Link>
            <Nav.Link to="https://www.linkedin.com/in/haribodev/" as={Link} target="_blank" rel="noreferrer">LinkedIn</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar >
  );
}

export default Header;