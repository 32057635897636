import { Divider } from "@mui/material";
import React from "react";
import { Container } from "react-bootstrap";
import ArticleInfo from "./ArticleInfo";

function Article({ article, content: Content }) {
  return (
    <div className="article">
      <h1>{article.articleTitle}</h1>

      <ArticleInfo
        author={article.articleAuthor}
        date={article.articleDate}
      />

      <Divider className="divider" />

      <Container className="vertical-center article-body" fluid>
        <p className="article-body-intro">
          {article.articleBody}
        </p>

        <Divider className="divider" />

        <Content />
      </Container>
    </div>
  );
}

export default Article;