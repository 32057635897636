import React from "react";
import { Col, Row } from "react-bootstrap";

function ArticleInfo(props) {
  return (
    <div className="article-info">
      <Row>
        <Col className="article-info-col-1">
          <p>Published By:</p>
        </Col>
        <Col className="article-info-col-2">
          <p>{props.author}</p>
        </Col>
      </Row>

      <Row>
        <Col className="article-info-col-1">
          <p>Published On:</p>
        </Col>
        <Col className="article-info-col-2">
          <p>{props.date}</p>
        </Col>
      </Row>
    </div>
  );
}

export default ArticleInfo;