import React from "react";
import ArticleCard from "../components/ArticleCard";
import { Container, Row } from "react-bootstrap";
import { Divider } from "@mui/material";
import { AwsGamedayDetails } from "./articles/AwsGameday";
import { AwsSubjectMatterExpertDetails } from "./articles/AwsSubjectMatterExpert";
import { StaticBlogOnAwsServerlessDetails } from "./articles/StaticBlogOnAwsServerless";
import { AwsLondonSummitDetails } from "./articles/AwsLondonSummit";

const articles = [
  AwsLondonSummitDetails,
  AwsGamedayDetails,
  AwsSubjectMatterExpertDetails,
  StaticBlogOnAwsServerlessDetails
];

function Blog() {
  return (
    <div className="blog">
      <h1>HariboDev Blog</h1>

      <Divider className="divider" />

      <Container>
        <Row className="article-card-row">
          {articles.map((article, index) => (
            <ArticleCard
              key={index}
              articleTitle={article.articleTitle}
              articleDate={article.articleDate}
              articleImage={article.articleImage}
              articleBody={article.articleBody}
              articleSlug={article.articleSlug}
            />
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Blog;