import { Divider } from "@mui/material";
import React from "react";
import ArticleImage from "../../components/ArticleImage";
import awsSmeImage from "../../assets/images/aws-sme.png";
import TextLink from "../../components/TextLink";
import Article from "../../components/Article";

export const AwsSubjectMatterExpertDetails = {
  articleTitle: "AWS Subject Matter Expert",
  articleDate: "2023-05-23",
  articleAuthor: "Harrison Cannon",
  articleImage: awsSmeImage,
  articleBody: "Ever heard of an AWS Subject Matter Expert or AWS SME? Well, I hadn't until I saw a recommendation to join the programme on LinkedIn. So, I decided to apply and see what it was all about.",
  articleSlug: "aws-subject-matter-expert"
};

function AwsSubjectMatterExpert() {
  function ArticleContent() {
    return (
      <div>
        <h2>Background</h2>

        <p>
          With a personal and professional interest in Cloud computing, I was interested to find out that AWS have a programme called <TextLink url="https://aws.amazon.com/certification/certification-sme-program/" text="AWS Subject Matter Experts (SMEs)" />. The programme is designed to help volunteers create and update AWS Certification training and exam materials to ensure that they are up to date with the latest AWS services and features.
        </p>

        <p>
          The programme is open to anyone who has an AWS Certification and is willing to volunteer their time to help improve the AWS Certification training and exam materials for the benefit of the AWS community.
        </p>

        <ArticleImage
          image={awsSmeImage}
          caption="AWS Subject Matter Expert"
        />

        <h4>Benefits</h4>

        <p>
          The AWS SME programme is very beneficial for anyone who is interested in Cloud computing and AWS. Not only are you helping to improve the AWS Certification training and exam materials, but you are also learning from other SMEs who may have more experience than you. The programme is also a great way to network with other SMEs and AWS employees.
        </p>

        <p>
          If you&apos;re like me you&apos;ll be glad to know that after completing each workshop you receive SME swag credits which can be used to purchase SME swag such as sweatshirts, t-shirts, mugs, and more.
        </p>

        <Divider className="divider" />

        <h2>My Experience</h2>

        <p>
          I applied to the AWS SME programme in January 2021 and was invited to attend a 4 week workshop in October 2021. To help me prepare, I was required to complete a course and exam on how to write effective questions or <i>items</i> as they call it. The course was very informative and provided me with a good understanding of the tasks.
        </p>

        <p>
          Throughout the workshop, I was given the opportunity to write questions and the opportunity to review questions written by other SMEs. Every couple of days, a number of SMEs would join a call to discuss the questions that we had written and reviewed. This was a great opportunity to meet other SMEs and learn from their experiences. Each question was anonymised so that we could provide honest feedback without bias.
        </p>

        <Divider className="divider" />

        <h2>Closing Thoughts</h2>

        <p>
          Overall, I have had a very positive experience with the AWS SME programme. I have enjoyed meeting other SMEs and learning from their experiences. I would definitely recommend the programme to anyone who has an AWS Certification and is interested in helping to improve the AWS Certification training and exam materials.
        </p>

        <p>
          If you are interested in finding out more about the AWS SME programme, you can visit the <TextLink url="https://aws.amazon.com/certification/certification-sme-program/" text="AWS website" />.
        </p>
      </div>
    );
  }

  return <Article
    article={AwsSubjectMatterExpertDetails}
    content={ArticleContent}
  />;
}

export default AwsSubjectMatterExpert;