import { Divider } from "@mui/material";
import React from "react";
import awsSummitImage from "../../assets/images/aws-london-summit/aws-summit.png";
import Article from "../../components/Article";
import awsSummitEntrance from "../../assets/images/aws-london-summit/aws-summit-entrance.jpeg";
import ArticleImage from "../../components/ArticleImage";

export const AwsLondonSummitDetails = {
  articleTitle: "AWS London Summit",
  articleDate: "2023-06-11",
  articleAuthor: "Harrison Cannon",
  articleImage: awsSummitImage,
  articleBody: "In June 2023, I attended the AWS Summit in London with some colleagues and friends. The event was held at the ExCel London and it was a great opportunity to learn about the latest AWS technologies and network with other AWS experts.",
  articleSlug: "aws-london-summit"
};

function AwsLondonSummit() {
  function ArticleContent() {
    return (
      <div className="article-content">
        <h2>Background</h2>

        <p>
          AWS Summits are an opportunity for Tech and Cloud enthusiasts to connect, collaborate, and learn about the latest AWS technologies. The event is free to attend and is held in major cities around the world. The event is usually held within a single day and consists of a keynote, breakout sessions, and an expo.
        </p>

        <p>
          Each year, the theme of the AWS Summit seems to change. I&apos;m not sure if this is intentional, but it makes attending the event each year worthwhile. This year, there was definitely a bigger focus on IoT and Machine Learning. Over the last year, the advancements in this area have been mind blowing and it was great to see how AWS is helping to push the boundaries.
        </p>

        <ArticleImage
          image={awsSummitEntrance}
          caption="AWS Summit Entrance"
        />

        <Divider className="divider" />

        <h2>My Experience</h2>

        <h3>Keynote</h3>

        <p>
          The yearly Keynote is a great way to learn about the latest AWS technologies and how they are being used by customers. This year, the keynote was presented Swami Sivasubramanian (VP of Amazon Machine Learning), Tanuja Randery (MD of EMEA), Will Cavendish (Global Digital Services Leader), and Renee Hunt (Chief Technology Officer).
        </p>

        <p>
          As always, the Keynote filled up very quickly and (unfortunately) I was unable to get a seat this year. However, AWS did a great job of streaming the Keynote to each breakout room so I didn&apos;t miss out!
        </p>

        <p>
          Many attendees choose not to attend the Keynote, but I would highly recommend it. It&apos;s a great way to learn about the latest AWS technologies and how they are being used by customers. However, if you would rather not attend the Keynote, you can head straight into the expo to speak to AWS partners and customers.
        </p>

        <h3>Expo</h3>

        <p>
          The expo is a great way to learn about all the different AWS partners and customers. Each booth have representatives from each of the organisations and they are more than happy to answer any questions you may have. More often than not, the booths tend to have a demo of their product or service in action, which is a great visual way of learning about the different products.
        </p>

        <p>
          Dotted throughout the expo hall is fireside chat areas. Every now and then, AWS partners and customers host quick 15 minute talks addressing key topics in the industry. Attendance to these talks is first come first serve, but you might just find yourself walking past and catching a few minutes of a talk.
        </p>

        <p>
          If you&apos;re like me, you will probably spend most of your time in the expo and if you attend early enough in the day, you may also be able to get some free swag! Let&apos;s be honest, who doesn&apos;t love free swag?!
        </p>

        <h3>Breakout Sessions</h3>

        <p>
          Throughout the day, AWS and its partners host breakout sessions. Information about these sessions are advertised throughout the expo and on the AWS Summit app and are a great way to learn. The sessions are usually 45 minutes long and target different skill levels. I would highly recommend attending as many of these sessions as possible.
        </p>

        <p>
          Unfortunately, this year a lot of the breakout sessions were either full or overlapped with other ones that I wanted to attend. However, (yet again) AWS have done a great job of recording all the sessions and making them available on YouTube. So, if you missed a few sessions because you were blindsighted by the amount of swag in the expo hall or you simply couldn&apos;t attend, you can catch up on YouTube.
        </p>

        <h3>Networking</h3>

        <p>
          Ranging from talking to customers and partners at the expo to going out for drinks after the event, the AWS Summit offers lots of networking opportunities. I would highly recommend taking advantage of these opportunities as you never know who you might meet!
        </p>

        <p>
          In earlier years, I had attended post-summit networking events hosted by AWS partners. However, this year I attended the AWS ComSum event. This was a community driven event and its organisers did a great job of organising. With a free bar (to a certain limit) and great canapés, it was a great way to end the day.
        </p>

        <p>
          As I mentioned before, there are a lot of post-summit networking events, but there are also a lot of pre-summit events too. Be sure to keep an eye out for these on LinkedIn or on Meetup as the AWS community is definitely an active one.
        </p>

        <Divider className="divider" />

        <h2>Closing Thoughts</h2>

        <p>
          As this was my second AWS Summit, I was able to attend more breakout sessions and speak to more partners of AWS. In previous years I was more focussed on the expo and networking (and swag!). By changing my strategy, I managed to learn more about the advancements in AWS over the last year and was even able to give a talk internally to help spread the word of the event and knowledgeshare what I had learnt.
        </p>

        <p>
          So, if you&apos;re on the fence about whether to attend an AWS Summit and assuming they&apos;re all just as amazing as the London one, I would highly recommend attending!
        </p>
      </div>
    );
  }

  return <Article
    article={AwsLondonSummitDetails}
    content={ArticleContent}
  />;
}

export default AwsLondonSummit;